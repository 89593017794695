<template>
    <div>
        <div class="pageheader pd-t-25 pd-b-15">
            <div class="pd-t-5 pd-b-5">
                <h1 class="pd-0 mg-0 tx-20 text-overflow">Profil Pengguna</h1>
            </div>
            <div class="breadcrumb pd-0 mg-0">
                <a class="breadcrumb-item" href="#"><i class="fa fa-user mg-r-5"></i>Profil Pengguna</a>
            </div>
        </div> 
        
        <div class="row justify-content-center">
            <div class="col-sm-12">
                <div class="card mb-3">
                    <div class="modal-card">

                        <div class="bg-neutral card-body">   
                            <form @submit.prevent="submitForm" enctype="multipart/form-data">
                            <div class="row">
                                <div class=" col-6">
                                    <div class="webnots-warning webnots-notification-box">Informasi ini akan digunakan untuk kelengkapan pada surat!</div>
                                    <div class="form-group row align-items-center">
                                        <label class="col-sm-12">Dinas &emsp;&emsp;: &emsp;<b>{{ userdata.name }}</b></label>
                                        <label class="col-sm-12">Username</label>
                                        <div class="col-sm-12">
                                            <input  type="text" v-model="form.username" value="" class="form-control">
                                        </div>
                                    </div>
                                    <div style="margin:1px;background-color:#f2f2f2;border-radius:5px"  class="form-group row align-items-center">
                                        <input type="hidden" v-model="form.id">
                                        <input type="hidden" v-model="form.roles">
                                        <label class="mt-3 col-sm-12">Kata Sandi</label>
                                        <div class="col-sm-12">
                                            <input type="password" @keyup="matchPassword()" v-model="form.password" value="" class="form-control">
                                        </div>
                                        <label class="mt-3 col-sm-12">Ulang Kata Sandi</label>
                                        <div class="mb-3 col-sm-12">
                                            <input @keyup="matchPassword()" v-model="form.password_confirm" type="password" value="" class="form-control">
                                        </div>
                                        <span class="ml-3 mb-2" v-if="checkPassword === 0"><a class="text-danger">Password Tidak Sesuai</a></span>
                                        <span class="ml-3 mb-2" v-if="checkPassword === 1"><a class="text-success">Password Sesuai</a></span>
                                    </div>
                                    <button v-if="checkPassword !== 0" type="submit" class="mt-2 col-12 btn btn-warning"><i class="fe fe-edit mr-2"></i>Perbaharui Password</button>
                                    <button v-if="checkPassword === 0" type="button" class="mt-2 col-12 btn btn-outline-danger"><i class="fe fe-alert-triangle mr-2"></i>Perbaiki Password</button>
                                </div>
                                <div class="col-6" style="height:400px;overflow-y: scroll;overflow-x: hidden;">
                                    
                                    <div style="margin:1px;background-color:#f2f2f2b8;border-radius:5px"  class="mt-2 form-group row align-items-center">
                                        <label class="mt-2 col-sm-12"><b>Informasi Kantor</b></label>
                                        <label class="col-sm-12">Alamat</label>
                                        <div class="mb-2 col-sm-12">
                                            <textarea  v-model="form.address" class="form-control" :disabled="userdata.role == 'administrator'"></textarea>
                                            <small class="text-danger">* Alamat akan dijadikan alamat pada kop surat yang dicetak</small>
                                        </div>
                                    </div>
                                    <div v-if="userdata.role == 'dinas'" style="margin:1px;background-color:#f2f2f2b8;border-radius:5px"  class="mt-2 form-group row align-items-center">
                                        <label  class="mt-2 col-sm-8"><b>Informasi Sekretariat</b></label>
                                        <label class="mt-2 col-sm-4">
                                            <button type="button" class="btn float-right btn-sm btn-danger bounce-button">
                                                <router-link :to="{ name:'profil.history_sekretariat'}">
                                                        <span class="text-white">Pergantian Sekretariat</span>
                                                </router-link>
                                            </button>
                                        </label>
                                        <label class="col-sm-12">Nama</label>
                                        <div class="col-sm-12">
                                            <input  :disabled="userdata.role == 'administrator'" type="text" v-model="form.sekret_name" value="" class="form-control">
                                        </div>
                                        <label class="mt-2 col-sm-12">NIP</label>
                                        <div class="mb-2 col-sm-12">
                                            <input  :disabled="userdata.role == 'administrator'" v-model="form.sekret_nip" type="text" value="" class="form-control">
                                            <small class="text-danger">* Data berikut merupakan data yang sedang aktif menjabat</small>
                                        </div>
                                    </div>
                                    <div v-if="userdata.role != 'bidang'" style="margin:1px;background-color:#f2f2f2b8;border-radius:5px"  class="mt-2 form-group row align-items-center">
                                        <label v-if="userdata.role == 'uptd'" class="mt-2 col-sm-8"><b>Informasi Kepala UPTD</b></label>
                                        <label v-if="userdata.role == 'dinas'" class="mt-2 col-sm-8"><b>Informasi Kepala Dinas</b></label>
                                        <label class="mt-2 col-sm-4">
                                            <button type="button" class="btn float-right btn-sm btn-danger bounce-button">
                                                <router-link :to="{ name:'profil.history_kadis'}">
                                                        <span v-if="userdata.role == 'uptd'" class="text-white">Pergantian Kepala UPTD</span>
                                                        <span v-if="userdata.role == 'dinas'" class="text-white">Pergantian Kepala Dinas</span>
                                                </router-link>
                                            </button>
                                        </label>
                                        <label class="col-sm-12">Nama</label>
                                        <div class="col-sm-12">
                                            <input  :disabled="userdata.role == 'administrator'" type="text" v-model="form.kadin_name" value="" class="form-control">
                                        </div>
                                        <label class="mt-2 col-sm-12">NIP</label>
                                        <div class="mb-2 col-sm-12">
                                            <input  :disabled="userdata.role == 'administrator'" v-model="form.kadin_nip" type="text" value="" class="form-control">
                                        </div>
                                        <label class="col-sm-12">Pangkat/Golongan</label>
                                        <div class="mb-2 col-sm-12">
                                            <select :disabled="userdata.role == 'administrator'" class="form-control" v-model="form.kadin_gol">
                                                <option value="">- Pilih Pangkat/Gol -</option>
                                                <option value="Juru muda/Ia">Juru muda/Ia</option>
                                                <option value="Juru Muda Tk.I/Ib">Juru Muda Tk.I/Ib</option>
                                                <option value="Juru/Ic">Juru/Ic</option>
                                                <option value="Juru Tk I/Id">Juru Tk I/Id</option>
                                                <option value="Pengatur Muda/IIa">Pengatur Muda/IIa</option>
                                                <option value="Pengatur Muda Tk.I/IIb">Pengatur Muda Tk.I/IIb</option>
                                                <option value="Pengatur/IIc">Pengatur/IIc</option>
                                                <option value="Pengatur Tk.I/IId">Pengatur Tk.I/IId</option>
                                                <option value="Penata Muda/IIIa">Penata Muda/IIIa</option>
                                                <option value="Penata Muda Tk.I/IIIb">Penata Muda Tk.I/IIIb</option>
                                                <option value="Penata/IIIc">Penata/IIIc</option>
                                                <option value="Penata Tk.I/IIId">Penata Tk.I/IIId</option>
                                                <option value="Pembina/Iva">Pembina/Iva</option>
                                                <option value="Pembina Tk.I/Ivb">Pembina Tk.I/Ivb</option>
                                                <option value="Pembina Utama Muda/Ivc">Pembina Utama Muda/Ivc</option>
                                                <option value="Pembina Utama Madya/Ivd">Pembina Utama Madya/Ivd</option>
                                                <option value="Pembina Utama/Ive">Pembina Utama/Ive</option>
                                            </select>
                                            <small class="text-danger">* Data berikut merupakan data yang sedang aktif menjabat</small>
                                        </div>
                                    </div>
                                    <div v-if="userdata.role == 'bidang'" style="margin:1px;background-color:#f2f2f2b8;border-radius:5px;display:none"  class="mt-2 form-group row align-items-center">
                                        <label class="mt-2 col-sm-12"><b>Informasi Kepala Bidang</b></label>
                                        
                                        <label class="col-sm-12">Nama</label>
                                        <div class="col-sm-12">
                                            <input  :disabled="userdata.role == 'administrator'" type="text" v-model="form.kabid_name" value="" class="form-control">
                                        </div>
                                        <label class="mt-2 col-sm-12">NIP</label>
                                        <div class="mb-2 col-sm-12">
                                            <input  :disabled="userdata.role == 'administrator'" v-model="form.kabid_nip" type="text" value="" class="form-control">
                                        </div>
                                        <label class="col-sm-12">Pangkat/Golongan</label>
                                        <div class="mb-2 col-sm-12">
                                            <select :disabled="userdata.role == 'administrator'" class="form-control" v-model="form.kabid_gol">
                                                <option value="">- Pilih Pangkat/Gol -</option>
                                                <option value="Juru muda/Ia">Juru muda/Ia</option>
                                                <option value="Juru Muda Tk.I/Ib">Juru Muda Tk.I/Ib</option>
                                                <option value="Juru/Ic">Juru/Ic</option>
                                                <option value="Juru Tk I/Id">Juru Tk I/Id</option>
                                                <option value="Pengatur Muda/IIa">Pengatur Muda/IIa</option>
                                                <option value="Pengatur Muda Tk.I/IIb">Pengatur Muda Tk.I/IIb</option>
                                                <option value="Pengatur/IIc">Pengatur/IIc</option>
                                                <option value="Pengatur Tk.I/IId">Pengatur Tk.I/IId</option>
                                                <option value="Penata Muda/IIIa">Penata Muda/IIIa</option>
                                                <option value="Penata Muda Tk.I/IIIb">Penata Muda Tk.I/IIIb</option>
                                                <option value="Penata/IIIc">Penata/IIIc</option>
                                                <option value="Penata Tk.I/IIId">Penata Tk.I/IIId</option>
                                                <option value="Pembina/Iva">Pembina/Iva</option>
                                                <option value="Pembina Tk.I/Ivb">Pembina Tk.I/Ivb</option>
                                                <option value="Pembina Muda/Ivc">Pembina Muda/Ivc</option>
                                                <option value="Pembina Madya/Ivd">Pembina Madya/Ivd</option>
                                                <option value="Pembina Utama/Ive">Pembina Utama/Ive</option>
                                            </select>
                                            <small class="text-danger">* Data berikut merupakan data yang sedang aktif menjabat</small>
                                        </div>
                                    </div>
                                    <div v-if="userdata.role != 'uptd'" style="margin:1px;background-color:#f2f2f2b8;border-radius:5px"  class="mt-2 form-group row align-items-center">
                                        <label class="mt-2 col-sm-8"><b>Informasi Pengurus Barang</b></label>
                                         <label class="mt-2 col-sm-4">
                                            <button type="button" class="btn float-right btn-sm btn-danger bounce-button">
                                                <router-link :to="{ name:'profil.history_pengurus'}">
                                                        <span class="text-white">Pergantian Pengurus Barang</span>
                                                </router-link>
                                            </button>
                                        </label>
                                        <label class="col-sm-12">Nama</label>
                                        <div class="col-sm-12">
                                            <input :disabled="userdata.role == 'administrator'" type="text" v-model="form.pj_name" value="" class="form-control">
                                        </div>
                                        <label class="mt-2 col-sm-12">NIP</label>
                                        <div class="mb-2 col-sm-12">
                                            <input :disabled="userdata.role == 'administrator'" v-model="form.pj_nip" type="text" value="" class="form-control">
                                        </div>
                                        <label class="col-sm-12">Pangkat/Golongan</label>
                                        <div class="mb-2 col-sm-12">
                                            <select :disabled="userdata.role == 'administrator'" class="form-control" v-model="form.pj_gol">
                                                <option value="">- Pilih Pangkat/Gol -</option>
                                                <option value="Juru muda/Ia">Juru muda/Ia</option>
                                                <option value="Juru Muda Tk.I/Ib">Juru Muda Tk.I/Ib</option>
                                                <option value="Juru/Ic">Juru/Ic</option>
                                                <option value="Juru Tk I/Id">Juru Tk I/Id</option>
                                                <option value="Pengatur Muda/IIa">Pengatur Muda/IIa</option>
                                                <option value="Pengatur Muda Tk.I/IIb">Pengatur Muda Tk.I/IIb</option>
                                                <option value="Pengatur/IIc">Pengatur/IIc</option>
                                                <option value="Pengatur Tk.I/IId">Pengatur Tk.I/IId</option>
                                                <option value="Penata Muda/IIIa">Penata Muda/IIIa</option>
                                                <option value="Penata Muda Tk.I/IIIb">Penata Muda Tk.I/IIIb</option>
                                                <option value="Penata/IIIc">Penata/IIIc</option>
                                                <option value="Penata Tk.I/IIId">Penata Tk.I/IIId</option>
                                                <option value="Pembina/Iva">Pembina/Iva</option>
                                                <option value="Pembina Tk.I/Ivb">Pembina Tk.I/Ivb</option>
                                                <option value="Pembina Muda/Ivc">Pembina Muda/Ivc</option>
                                                <option value="Pembina Madya/Ivd">Pembina Madya/Ivd</option>
                                                <option value="Pembina Utama/Ive">Pembina Utama/Ive</option>
                                            </select>
                                        </div>
                                        <label class="col-sm-12">Jabatan</label>
                                        <div class="mb-2 col-sm-12">
                                            <input  :disabled="userdata.role == 'administrator'" v-model="form.pj_jab" type="text" value="" class="form-control">
                                            <small class="text-danger">* Data berikut merupakan data yang sedang aktif menjabat</small>
                                        </div>
                                    </div>
                                    <button type="submit" class="mt-2 col-12 btn btn-warning"><i class="fe fe-edit mr-2"></i>Perbaharui Informasi</button>
                                    
                                </div>
                            </div>
                            </form>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    export default {
        metaInfo: {
            title: 'SI',
            titleTemplate: '%s - Data Persediaan'
        },
        data() {
            return {
                baseUrl: this.$apiconfig,
                checkPassword: '',
                form: {
                    id: '',
                    username: '',
                    roles: '',
                    password: '',
                    password_confirm: '',
                    sekret_name: '',
                    sekret_nip: '',
                    kabid_name: '',
                    kabid_nip: '',
                    kabid_gol: '',
                    kadin_name: '',
                    kadin_nip: '',
                    kadin_gol: '',
                    pj_name: '',
                    pj_nip: '',
                    pj_gol: '',
                    pj_jab: '',
                    first_no: '',
                    last_no: '',
                    address: '',
                },
            }
        },
        created() {
            this.$store.dispatch('loadUserData');
            this.getData();
        },
        computed: mapState(['userdata']),
        methods: {
            getData() {
                this.$http.get(this.baseUrl + 'general/profil', {
                    params: {
                        id: this.userdata.id,
                    }
                })
                    .then((response) => {
                        this.form.id = response.data.data[0].id;
                        this.form.username = response.data.data[0].username;
                        this.form.sekret_nip = response.data.data[0].sekret_nip;
                        this.form.sekret_name = response.data.data[0].sekret_name;
                        this.form.kabid_nip = response.data.data[0].kabid_nip;
                        this.form.kabid_name = response.data.data[0].kabid_name;
                        this.form.kabid_gol = response.data.data[0].kabid_gol;
                        this.form.kadin_nip = response.data.data[0].kadin_nip;
                        this.form.kadin_name = response.data.data[0].kadin_name;
                        this.form.kadin_gol = response.data.data[0].kadin_gol;
                        this.form.pj_nip = response.data.data[0].pj_nip;
                        this.form.pj_name = response.data.data[0].pj_name;
                        this.form.pj_gol = response.data.data[0].pj_gol;
                        this.form.pj_jab = response.data.data[0].pj_jab;
                        this.form.first_no = response.data.data[0].first_no;
                        this.form.roles = response.data.data[0].roles;
                        this.form.address = response.data.data[0].address;
                        this.form.password = '';
                        this.form.password_confirm = '';
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            },
            submitForm() {
                this.$http.post(this.baseUrl + 'general/profil/save', this.form)
                    .then(() => {
                        this.$swal({
                            icon: 'success',
                            title: "success",
                            text: 'Data berhasil diperbaharui',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        if(this.form.password == this.form.password_confirm && this.form.password != ''){
                            this.$router.push('/signout');
                        }else{
                            this.getData()
                        }
                    })
                    .catch(error => {
                        console.log(error.response.data)
                    });
            },
            matchPassword() {
                if (this.form.password == this.form.password_confirm && this.form.password_confirm != '') {
                    this.checkPassword = 1;
                } else {
                    this.checkPassword = 0;
                }
                if(this.form.password == '' && this.form.password_confirm == ''){
                    this.checkPassword = '';
                }
            },
        },
    };
</script>
<style>
    /* style sweetalert */
    .swal2-html-container {
        display: block;
        text-align: center;
    }
</style>